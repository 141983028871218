export const plans = [
    {
        id:1,
        plan:'Classic',
        amount:'139425'
    },
    {
        id:2,
        plan:'Gold',
        amount:'191673'
    },
    {
        id:3,
        plan:'Diamond',
        amount:'338797'
    },
    {
        id:4,
        plan:'Diamond+',
        amount:'515978'
    }
]